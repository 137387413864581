import axios from "axios";
const isDev = process.env.VUE_APP_MODE === "test";

let service = axios.create({
//   baseURL: isDev ? "http://192.168.0.164" : "https://uapi.233.com",
  baseURL: 'https://uapi.233.com'
});
// export var host = window.location.host;
// export var host = "ks.rrxue.cn"
export var host = "keyun.rrxue.cn"
// host = "wx.hdgk.cn"
// host = "wx.029985.com"

service.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("usertoken");
    if (token == undefined) {
      token = "";
      if (process.env.NODE_ENV === "development") {
        // token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjE3MTY2NDQ4LCJ1c2VybmFtZSI6InJyeHVlIiwidW5pb25pZCI6IjI3Mjk0IiwiaXNzdWVyIjoidXNlciIsImF1ZGllbmNlIjoiIn0.v1Rlxcz99uv2D-Yddp00iJ329gv-A6V6HLNGiApUThM"
        // token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyaWQiOjE0NjQ5NzEyLCJ1c2VybmFtZSI6Ijk2NjQ2MDZhIiwidW5pb25pZCI6IjUzNjAzIiwiaXNzdWVyIjoidXNlciIsImF1ZGllbmNlIjoiIn0.kZYaOGayYhOWg-wGv16BavziWVvaKTKje129s-89Kmg"
        token = ""
      }
    }
    config.params = {
      stamp: new Date().getTime(),
      host: window.location.host,
      token: token,
      ...config.params,
    };
    if (process.env.NODE_ENV === "development") {
      config.params.host = host;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (data.ResultDescription && !data.ResultDescription === "success") {
      // ElementUI.Message({ message: data.ResultDescription || '服务异常', type: "error" })
      return Promise.reject(data);
    } else {
      return data;
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default service;
